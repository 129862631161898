
// INVOICES
export const INVOICE_INCLUDE_WITH_DERIVED_ITEMS = 'company/headquarter_address,locations,locations/company,location/contacts,locations/address,department,client_department,vendor,vendor/contacts,vendor/logo,vendor/item_types,invoice_items,invoice_items/item/item_type,invoice_items/item,invoice_items/measure_unit,invoice_items/item/department,company,attachments,invoice_items/derived_item,invoice_items/derived_item/resource,invoice_items/derived_item/generic_rate_item,company/contacts,company/logo,trips,invoice_resources,sub_invoices,sub_invoices/invoice_resources,sub_invoices/locations,sub_invoices/invoice_items,sub_invoices/invoice_items/derived_item,sub_invoices/invoice_items/derived_item/resource,sub_invoices/invoice_items/item/item_type,sub_invoices/invoice_items/measure_unit,client_contact,invoice_items/invoice_item_taxes,invoice_items/invoice_item_po_materials,responsible_billing_party, company/vendor_invoice_rejection_form_setting, calculated_status'
export const INVOICE_INCLUDE = 'locations,locations/company,location/contacts,location/address,department,client_department,vendor,vendor/logo,vendor/contacts,vendor/item_types,invoice_items,invoice_items/item/item_type,invoice_items/item,invoice_items/measure_unit,invoice_items/item/department,attachments,company,company/logo,company/headquarter_address,company/contacts,invoice_resources,sub_invoices,sub_invoices/locations,sub_invoices/locations/company,sub_invoices/invoice_items,sub_invoices/invoice_items/item/item_type,sub_invoices/invoice_items/item,sub_invoices/invoice_items/measure_unit,sub_invoices/invoice_items/item/department,sub_invoices/invoice_resources,sub_invoices/invoice_payments,client_contact,invoice_items/invoice_item_taxes,responsible_billing_party, company/vendor_invoice_rejection_form_setting'
// PROPOSALS
export const PROPOSAL_INCLUDE_DERIVED_ITEM = 'attachments,proposal_documents,company,department,company/logo,company/headquarter_address,vendor,vendor/logo,location,proposal_notes,proposal_notes/actor,proposal_notes/actor/image,contact_user,client_contact,proposal_assignment,proposal_items/item,proposal_items/derived_item,proposal_items/item/item_type,proposal_trips/trip,company/contacts,vendor/contacts,location/contacts,proposal_items/proposal_item_taxes'
export const PROPOSAL_INCLUDE = 'attachments,proposal_documents,company,department,company/logo,company/headquarter_address,company/contacts,vendor/contacts,location/contacts,vendor,vendor/logo,location,proposal_notes,proposal_notes/actor,proposal_notes/actor/image,contact_user,client_contact,proposal_assignment,proposal_items/item,proposal_items/item/item_type,proposal_items/proposal_item_taxes'
// CLIENTS
export const CLIENT_INCLUDE = 'contacts,billing_address,address,logo,headquarter_address,business_address'

export const CLIENT_PROFILE_INCLUDE = 'logo,headquarter_address,contacts,dba,ivr,billing_address,service_channel_subscriber_connection'
// LOCATIONS
export const LOCATION_INCLUDE = 'company,billing_address,headquarter_address,contacts,address,service_rates,vendors,vendors/work_order_setting,work_order/vendor/location_setting'
export const BULK_GEN_LOCATION_INCLUDE = 'company,address,billing_address'
// PURCHASE ORDERS
export const PO_INCLUDE_DERIVED_ITEM = 'company,work_order,trip,vendor/contacts,company/contacts,location/contacts,company/logo,company/headquarter_address,purchase_order_items/item/item_type,purchase_order_items/item,department,attachments,locations,delivery_location,delivery_client,purchase_order_items/measure_unit,purchase_order_emails,purchase_order_assignment,service_location,service_location/company,service_location/address,attachments,purchase_order_documents,vendor,vendor/logo,location,purchase_order_notes,purchase_order_notes/actor,purchase_order_notes/actor/image,contact_user,client_contact,purchase_order_items/derived_item,purchase_order_trips/trip,purchase_order_items/purchase_order_item_taxes'
export const PO_INCLUDE = 'company,work_order,trip,company/logo,company/contacts,company/headquarter_address,vendor,vendor/logo,vendor/contacts,purchase_order_items/item/item_type,purchase_order_items/item,department,attachments,locations,location/contacts,delivery_location,delivery_client,purchase_order_items/measure_unit,purchase_order_emails,purchase_order_assignment,service_location,service_location/company,service_location/address,purchase_order_items/purchase_order_item_taxes'
// LOGBOOK
export const LOGBOOK_INCLUDE = 'service_exception,service_photos,tradesmen_trip,tradesmen_trip/trip,tradesmen_trip/trip/trade,tradesmen_trip/trip/trip_trade_services/trade_service'
// VENDOR
export const VENDORS_INCLUDE = 'logo,headquarter_address,vendor_number,name,drip,contacts,contacts/image,docu_sign_signer,company_services,checked_for_1099,restricted_use,restricted_note,one_time_only,recruiter,comments,wo_message,is_ciwo_enable,payment_term,termination,compliance_requirments,alert_notes,w9_status,coi_documents_status,compliance_requirments,dispatch_method,assigned_payment_term,billing_address,vendor_status_invoice_instruction,vendor_status_invoice_instruction/work_order_status,po_address,shipping_address,payment_address,vendor_coins_connection'

export const VENDORS_PROFILE_INCLUDE = 'logo,headquarter_address,vendor_number,name,drip,contacts,docu_sign_signer,company_services,checked_for_1099,restricted_use,restricted_note,one_time_only,recruiter,comments,wo_message,is_ciwo_enable,payment_term,termination,compliance_requirments,dispatch_method,assigned_payment_term,vendor_status_invoice_instruction,vendor_status_invoice_instruction/work_order_status,vendor_coins_connection'
// TEAM MEMBERS
export const TEAM_MEMBERS_INCLUDE = 'company,role,user,user/image,company_user_rate,territories,additional_informations,sisense_dashboard_role_features,departments'
// WORK ORDER
export const WORK_ORDER_DETAILS_INCLUDE = 'work_order_status,company,company/logo,locations,locations/address,trades,external_connections,trips/external_connections,department,rounding_template,work_order_trade_services/work_order_trade,work_order_trade_services/work_order_tasks, work_order_activities,vendor,vendor/logo'

// ASSETS
export const ASSETS_INCLUDE = 'manufacturer,location,equipment_type,equipment_category,equipment_trade,decommissioned,equipment_area,physical_location,floor,area_served,equipment_model,equipment_attachments,equipment_reports,recent_surveys,equipment_location,current_condition,age,manufacture_date,last_activity_date,tag,installation,warranty,decommission,customer_equipment_code,tax_captalization_status,is_component,total_spend,company,company_labels,profile_picture,parent_equipment'
