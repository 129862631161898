import {
  VENDOR_NAME,
  VENDOR_COINS_ID,
  VENDOR_STATUS
} from '@/constants/filters/resources/vendor'

import {
  SITE_NAME,
  SITE_COUNTRY,
  SITE_CITY,
  SITE_ZIP,
  SITE_ADDRESS,
  SITE_REGION,
  SITE_STATE
} from '@/constants/filters/resources/site'

import {
  WORK_ORDER_NUMBER,
  WORK_ORDER_START_DATE,
  WORK_ORDER_CREATED_DATE,
  WORK_ORDER_EXPIRATION_DATE,
  WORK_ORDER_TRIP_EXPIRATION_DATE,
  WORK_ORDER_GENERATION_DATE,
  WORK_ORDER_TYPE,
  WORK_ORDER_PRIORITY,
  WORK_ORDER_TRIP_STATUS,
  WORK_ORDER_STATUS,
  WORK_ORDER_CUSTOM_STATUS,
  WORK_ORDER_CALL,
  WORK_ORDER_JOB_NAME,
  WORK_ORDER_DEPARTMENT_BRANCH,
  WORK_ORDER_FOLLOW_UP_DATE,
  WORK_ORDER_CLIENT_CONTACT,
  WORK_ORDER_TRIP_CATEGORY,
  WORK_ORDER_COMPLETION_DATE,
  WORK_ORDER_TRIP_COMPLETION_DATE,
  WORK_ORDER_SERVICE,
  WORK_ORDER_TRADE,
  WORK_ORDER_BILLING_METHOD,
  WORK_ORDER_EXTERNAL_REGION,
  WORK_ORDER_SERVICE_CHANNEL_CONNECTION,
  WORK_ORDER_CORRIGO_CONNECTION,
  WORK_ORDER_SERVICE_CHECK_IN_TIME,
  WORK_ORDER_SERVICE_CHECK_OUT_TIME
} from '@/constants/filters/resources/workOrder'

import {
  PROPOSAL_NUMBER,
  PROPOSAL_CUSTOM_NAME,
  PROPOSAL_STATUS,
  PROPOSAL_DATE_OF_ISSUE,
  PROPOSAL_EXPIRATION_DATE
  // PROPOSAL_CLIENT_NAME,
  // PROPOSAL_SITE_NAME
} from '@/constants/filters/resources/proposal'

import {
  WEATHER_EVENT,
  WEATHER_EVENT_START_DATE,
  WEATHER_EVENT_END_DATE
} from '@/constants/filters/resources/weatherEvent'

import {
  INVOICE_RECEIVED_DATE,
  INVOICE_REGION,
  INVOICE_EXTERNAL_REGION
} from '@/constants/filters/resources/invoice'

import { CLIENT_NAME } from '@/constants/filters/resources/client'

import { BROKER } from '@/constants/permissions'
import { useSession } from '@/use/session'
import { CompanyRoleTypes } from '@/types/enums'
import {
  TRIP_CREATION_DATE,
  TRIP_SERVICE_CHECK_IN_TIME,
  TRIP_SERVICE_CHECK_OUT_TIME
} from '../resources/trip'

export const generateVendorInvoiceFilters = () => {
  const { session } = useSession()

  const availableWorkOrderFilters = () => {
    const baseFilters: any = [
      WORK_ORDER_NUMBER({ pinned: true, isPrimary: true }),
      WORK_ORDER_START_DATE({ pinned: false, isPrimary: false }),
      WORK_ORDER_CREATED_DATE({ pinned: false, isPrimary: false }),
      WORK_ORDER_EXPIRATION_DATE({ pinned: false, isPrimary: false }),
      WORK_ORDER_TRIP_EXPIRATION_DATE({ pinned: false, isPrimary: false }),
      WORK_ORDER_GENERATION_DATE({ pinned: false, isPrimary: false }),
      WORK_ORDER_TYPE({ pinned: false, isPrimary: false }),
      // WORK_ORDER_PRIORITY({ pinned: false, isPrimary: false }),
      WORK_ORDER_TRIP_STATUS({ pinned: false, isPrimary: false }),
      WORK_ORDER_STATUS({ pinned: false, isPrimary: false }),
      // WORK_ORDER_CALL({ pinned: false, isPrimary: false }),
      WORK_ORDER_JOB_NAME({ pinned: false, isPrimary: false }),
      // WORK_ORDER_DEPARTMENT_BRANCH({ pinned: false, isPrimary: false }),
      WORK_ORDER_FOLLOW_UP_DATE({ pinned: false, isPrimary: false }),
      // WORK_ORDER_CLIENT_CONTACT({ pinned: false, isPrimary: false }),
      // WORK_ORDER_TRIP_CATEGORY({ pinned: false, isPrimary: false }),
      // WORK_ORDER_COMPLETION_DATE({ pinned: false, isPrimary: false }),
      WORK_ORDER_TRIP_COMPLETION_DATE({ pinned: false, isPrimary: false }),
      WORK_ORDER_SERVICE({ pinned: false, isPrimary: false }),
      WORK_ORDER_TRADE({ pinned: false, isPrimary: false }),
      WORK_ORDER_BILLING_METHOD({ pinned: false, isPrimary: false }),
      WORK_ORDER_SERVICE_CHANNEL_CONNECTION({ pinned: false, isPrimary: false }),
      WORK_ORDER_CORRIGO_CONNECTION({ pinned: false, isPrimary: false }),
      WORK_ORDER_SERVICE_CHECK_IN_TIME({ pinned: false, isPrimary: false }),
      WORK_ORDER_SERVICE_CHECK_OUT_TIME({ pinned: false, isPrimary: false })
    ]
    if (session.currentCompanyType === BROKER) {
      baseFilters.push(
        WORK_ORDER_EXTERNAL_REGION({ pinned: false, isPrimary: false }),
        WORK_ORDER_CUSTOM_STATUS({ pinned: false, isPrimary: false })
      )
    }
    return baseFilters
  }

  const availableSiteFilters = () => {
    const baseFilters: any = [
      SITE_NAME({ pinned: true, isPrimary: true }),
      SITE_STATE({ pinned: false, isPrimary: false })
    // SITE_COUNTRY({ pinned: false, isPrimary: false }),
    // SITE_CITY({ pinned: false, isPrimary: false }),
    // SITE_ZIP({ pinned: false, isPrimary: false }),
    // SITE_ADDRESS({ pinned: false, isPrimary: false })
    ]
    if (session.currentCompanyType === BROKER) {
      baseFilters.push(
        SITE_REGION({ pinned: false, isPrimary: false })
      )
    }
    return baseFilters
  }

  const availableTripFilters = () => {
    const baseFilters: any = [
      TRIP_CREATION_DATE({ pinned: false, isPrimary: false }),
      TRIP_SERVICE_CHECK_IN_TIME({ pinned: false, isPrimary: false }),
      TRIP_SERVICE_CHECK_OUT_TIME({ pinned: false, isPrimary: false })
    ]
    return baseFilters
  }

  return {
    filters: [
      VENDOR_NAME({ pinned: true, isPrimary: true }),
      // VENDOR_COINS_ID({ pinned: false, isPrimary: false }),
      VENDOR_STATUS({ pinned: false, isPrimary: false }),
      ...availableSiteFilters(),
      ...availableWorkOrderFilters(),
      PROPOSAL_NUMBER(false, { pinned: false, isPrimary: false }, { field: 'proposal_number' }),
      PROPOSAL_CUSTOM_NAME(false, { pinned: false, isPrimary: false }, { field: 'custom_proposal_name' }),
      PROPOSAL_STATUS(CompanyRoleTypes.CLIENT, { pinned: false, isPrimary: false }),
      PROPOSAL_DATE_OF_ISSUE({ pinned: false, isPrimary: false }),
      PROPOSAL_EXPIRATION_DATE({ pinned: false, isPrimary: false }),
      // PROPOSAL_CLIENT_NAME({ pinned: false, isPrimary: false })
      // PROPOSAL_SITE_NAME({ pinned: false, isPrimary: false })
      WEATHER_EVENT({ pinned: false, isPrimary: false }),
      WEATHER_EVENT_START_DATE({ pinned: true, isPrimary: true }),
      WEATHER_EVENT_END_DATE({ pinned: true, isPrimary: true }),
      CLIENT_NAME({ pinned: true, isPrimary: true }),
      ...availableTripFilters()
    ],
    groupBy: {
      value: 'vendors',
      items: [
        {
          label: 'Vendors',
          value: 'vendors'
        }
      ]
    },
    fieldMapping: {
      'vendor-name': {
        linkingTables: {
          vendors: ''
        },
        name: 'name',
        field: 'value',
        dynamicQueryKeys: {
          workOrdersName: 'trips_contractor_name',
          weatherEventsName: ''
        },
        rowTypesToQuery: [
          'vendors'
        ]
      },
      'vendor-coins-id': {
        linkingTables: {
          vendors: ''
        },
        name: 'coins_id',
        field: 'value',
        dynamicQueryKeys: {
          workOrdersName: 'coins_id',
          weatherEventsName: ''
        },
        rowTypesToQuery: [
          'vendors'
        ]
      },
      'vendor-status': {
        linkingTables: {
          vendors: ''
        },
        name: 'status',
        field: 'value',
        dynamicQueryKeys: {
          workOrdersName: 'vendor_status_label',
          weatherEventsName: ''
        },
        rowTypesToQuery: [
          'vendors'
        ]
      },
      'site-name': {
        linkingTables: {
          vendors: 'locations',
          sites: ''
        },
        name: 'name',
        field: 'value',
        dynamicQueryKeys: {
          workOrdersName: 'locations_name',
          weatherEventsName: 'locations_name'
        },
        rowTypesToQuery: [
          'vendors',
          'sites',
          'work-orders',
          'proposals',
          'events',
          'trips'
        ]
      },
      'site-country': {
        linkingTables: {
          vendors: 'locations',
          sites: ''
        },
        name: 'address_country',
        field: 'value',
        dynamicQueryKeys: {
          workOrdersName: 'locations_address_country',
          weatherEventsName: 'locations_address_country'
        },
        rowTypesToQuery: [
          'vendors',
          'sites'
        ]
      },
      'site-city': {
        linkingTables: {
          vendors: 'locations',
          sites: ''
        },
        name: 'address_city',
        field: 'value',
        dynamicQueryKeys: {
          workOrdersName: 'locations_address_city',
          weatherEventsName: 'locations_address_city'
        },
        rowTypesToQuery: [
          'vendors',
          'sites'
        ]
      },
      'site-zip': {
        linkingTables: {
          vendors: 'locations',
          sites: ''
        },
        name: 'address_zip_code',
        field: 'value',
        dynamicQueryKeys: {
          workOrdersName: 'locations_address_zip_code',
          weatherEventsName: 'locations_address_zip_code'
        },
        rowTypesToQuery: [
          'vendors',
          'sites'
        ]
      },
      'site-address': {
        linkingTables: {
          vendors: 'locations',
          sites: ''
        },
        name: 'address_full_address',
        field: 'value',
        dynamicQueryKeys: {
          workOrdersName: 'locations_address_full_address',
          weatherEventsName: 'locations_address_full_address'
        },
        rowTypesToQuery: [
          'vendors',
          'sites'
        ]
      },
      'site-region': {
        linkingTables: {
          vendors: '',
          sites: '',
          proposals: '',
          'work-orders': '',
          events: ''
        },
        name: 'work_order_locations_cc_territory_name',
        field: 'text',
        dynamicQueryKeys: {
          workOrdersName: 'work_order_locations_cc_territory_name',
          weatherEventsName: 'work_order_locations_cc_territory_name'
        },
        rowTypesToQuery: [
          'vendors',
          'sites',
          'work-orders',
          'proposals',
          'events'
        ]
      },
      'work-order-number': {
        linkingTables: {
          vendors: 'work_orders',
          sites: 'work_orders',
          proposals: 'work_order',
          'work-orders': '',
          events: ''
        },
        name: 'id',
        field: 'text',
        dynamicQueryKeys: {
          workOrdersName: 'id',
          weatherEventsName: 'id'
        },
        rowTypesToQuery: [
          'vendors',
          'sites',
          'work-orders',
          'proposals',
          'events'
        ]
      },
      'work-order-start-date': {
        linkingTables: {
          vendors: 'work_orders',
          sites: 'work_orders',
          proposals: 'work_order',
          'work-orders': '',
          events: ''
        },
        name: 'trips_start_date',
        field: 'value',
        dynamicQueryKeys: {
          workOrdersName: 'trips_start_date',
          weatherEventsName: 'trips_start_date'
        },
        rowTypesToQuery: [
          'vendors',
          'sites',
          'work-orders',
          'proposals',
          'events'
        ]
      },
      'work-order-created-date': {
        linkingTables: {},
        name: 'work_order_created_at',
        field: 'value',
        dynamicQueryKeys: {},
        rowTypesToQuery: [
          'vendors',
          'sites',
          'work-orders',
          'proposals',
          'events',
          'trips'
        ]
      },
      'work-order-expiration-date': {
        linkingTables: {},
        name: 'work_order_expiration_date',
        field: 'value',
        dynamicQueryKeys: {},
        rowTypesToQuery: [
          'vendors',
          'sites',
          'work-orders',
          'proposals',
          'events',
          'trips'
        ]
      },
      'work-order-trip-expiration-date': {
        linkingTables: {},
        name: 'expiration_date',
        field: 'value',
        dynamicQueryKeys: {},
        rowTypesToQuery: [
          'vendors',
          'sites',
          'work-orders',
          'proposals',
          'events',
          'trips'
        ]
      },
      'work-order-generation-date': {
        linkingTables: {},
        name: 'work_order_creation_date',
        field: 'value',
        dynamicQueryKeys: {},
        rowTypesToQuery: [
          'vendors',
          'sites',
          'work-orders',
          'proposals',
          'events',
          'trips'
        ]
      },
      'work-order-type': {
        linkingTables: {
          vendors: 'work_orders',
          sites: 'work_orders',
          proposals: 'work_order',
          'work-orders': '',
          events: ''
        },
        name: 'work_order_type',
        field: 'value',
        dynamicQueryKeys: {
          workOrdersName: 'work_order_type',
          weatherEventsName: 'work_order_type'
        },
        rowTypesToQuery: [
          'vendors',
          'sites',
          'work-orders',
          'proposals',
          'events'
        ]
      },
      'work-order-priority': {
        linkingTables: {
          vendors: 'work_orders',
          sites: 'work_orders',
          proposals: 'work_order',
          'work-orders': '',
          events: ''
        },
        name: 'priority',
        field: 'value',
        dynamicQueryKeys: {
          workOrdersName: 'priority',
          weatherEventsName: 'priority'
        },
        rowTypesToQuery: [
          'vendors',
          'sites',
          'work-orders',
          'proposals',
          'events'
        ]
      },
      'work-order-trip-status': {
        linkingTables: {
          vendors: 'work_order',
          sites: 'work_order',
          proposals: 'work_order',
          'work-orders': '',
          events: ''
        },
        name: 'extended_status_code',
        field: 'value',
        dynamicQueryKeys: {
          workOrdersName: 'extended_status_code',
          weatherEventsName: 'extended_status_code'
        },
        rowTypesToQuery: [
          'vendors',
          'sites',
          'work-orders',
          'proposals',
          'events'
        ]
      },
      'work-order-status': {
        linkingTables: {
          vendors: '',
          sites: '',
          proposals: '',
          'work-orders': '',
          events: ''
        },
        name: 'work_order_status_name',
        field: 'text',
        dynamicQueryKeys: {
          workOrdersName: 'work_order_work_order_status',
          weatherEventsName: 'work_order_work_order_status'
        },
        rowTypesToQuery: [
          'vendors',
          'sites',
          'work-orders',
          'proposals',
          'events'
        ]
      },
      'work-order-custom-status': {
        linkingTables: {
          vendors: 'work_orders',
          sites: 'work_orders',
          proposals: 'work_order',
          'work-orders': '',
          events: ''
        },
        name: 'work_order_status_name',
        field: 'value',
        dynamicQueryKeys: {
          workOrdersName: 'work_order_status_name',
          weatherEventsName: 'work_order_status_name'
        },
        rowTypesToQuery: [
          'vendors',
          'sites',
          'work-orders',
          'proposals',
          'events'
        ]
      },
      'work-order-call': {
        linkingTables: {
          vendors: 'work_orders',
          sites: 'work_orders',
          proposals: 'work_order',
          'work-orders': '',
          events: ''
        },
        name: 'job_number',
        field: 'value',
        dynamicQueryKeys: {
          workOrdersName: 'job_number',
          weatherEventsName: 'job_number'
        },
        rowTypesToQuery: [
          'vendors',
          'sites',
          'work-orders',
          'proposals',
          'events'
        ]
      },
      'work-order-job-name': {
        linkingTables: {},
        name: 'work_order_work_order_name',
        field: 'value',
        dynamicQueryKeys: {
        },
        rowTypesToQuery: [
          'vendors',
          'sites',
          'work-orders',
          'proposals',
          'events'
        ]
      },
      'work-order-department-branch': {
        linkingTables: {
          vendors: 'work_orders',
          sites: 'work_orders',
          proposals: 'work_order',
          'work-orders': '',
          events: ''
        },
        name: 'cu_cc_departments_name',
        field: 'value',
        dynamicQueryKeys: {
          workOrdersName: 'cu_cc_departments_name',
          weatherEventsName: 'cu_cc_departments_name'
        },
        rowTypesToQuery: [
          'vendors',
          'sites',
          'work-orders',
          'proposals',
          'events'
        ]
      },
      'work-order-follow-up-date': {
        linkingTables: {},
        name: 'work_order_follow_up_at',
        field: 'value',
        dynamicQueryKeys: {},
        rowTypesToQuery: [
          'vendors',
          'sites',
          'work-orders',
          'proposals',
          'events',
          'trips'
        ]
      },
      'work-order-client-contact': {
        linkingTables: {
          vendors: 'work_orders',
          sites: 'work_orders',
          proposals: 'work_order',
          'work-orders': '',
          events: ''
        },
        name: 'client_contact_full_name',
        field: 'value',
        dynamicQueryKeys: {
          workOrdersName: 'client_contact_full_name',
          weatherEventsName: 'client_contact_full_name'
        },
        rowTypesToQuery: [
          'vendors',
          'sites',
          'work-orders',
          'proposals',
          'events'
        ]
      },
      'work-order-trip-category': {
        linkingTables: {
          vendors: 'work_orders',
          sites: 'work_orders',
          proposals: 'work_order',
          'work-orders': '',
          events: ''
        },
        name: 'trips_trip_category_name',
        field: 'value',
        dynamicQueryKeys: {
          workOrdersName: 'trips_trip_category_name',
          weatherEventsName: 'trips_trip_category_name'
        },
        rowTypesToQuery: [
          'vendors',
          'sites',
          'work-orders',
          'proposals',
          'events'
        ]
      },
      // 'work-order-completion-date': {
      //   linkingTables: {
      //     vendors: 'work_orders',
      //     sites: 'work_orders',
      //     proposals: 'work_order',
      //     'work-orders': '',
      //     events: ''
      //   },
      //   name: '???',
      //   field: 'value',
      //   dynamicQueryKeys: {
      //     workOrdersName: '???',
      //     weatherEventsName: '???'
      //   },
      //   rowTypesToQuery: [
      //     'vendors',
      //     'sites',
      //     'work-orders',
      //     'proposals',
      //     'events'
      //   ]
      // },
      'work-order-trip-completion-date': {
        linkingTables: {
          vendors: 'work_order',
          sites: 'work_order',
          proposals: 'work_order',
          'work-orders': '',
          events: ''
        },
        name: 'completed_at',
        field: 'value',
        dynamicQueryKeys: {
          workOrdersName: 'completed_at',
          weatherEventsName: 'completed_at'
        },
        rowTypesToQuery: [
          'vendors',
          'sites',
          'work-orders',
          'proposals',
          'events',
          'trips'
        ]
      },
      'work-order-service': {
        linkingTables: {
          vendors: '',
          sites: '',
          proposals: '',
          'work-orders': '',
          events: ''
        },
        name: 'trade_services_name',
        field: 'value',
        dynamicQueryKeys: {
        },
        rowTypesToQuery: [
          'vendors',
          'sites',
          'work-orders',
          'proposals',
          'events'
        ]
      },
      'work-order-trade': {
        linkingTables: {
          vendors: '',
          sites: '',
          proposals: '',
          'work-orders': '',
          events: ''
        },
        name: 'trades_name',
        field: 'value',
        dynamicQueryKeys: {
        },
        rowTypesToQuery: [
          'vendors',
          'sites',
          'work-orders',
          'proposals',
          'events'
        ]
      },
      'work-order-billing-method': {
        linkingTables: {
          vendors: '',
          sites: '',
          proposals: '',
          'work-orders': '',
          events: ''
        },
        name: 'work_order_work_order_type',
        field: 'value',
        dynamicQueryKeys: {
        },
        rowTypesToQuery: [
          'vendors',
          'sites',
          'work-orders',
          'proposals',
          'events'
        ]
      },
      'external-region': {
        linkingTables: {
          vendors: '',
          sites: '',
          proposals: '',
          'work-orders': '',
          events: ''
        },
        name: 'work_order_locations_agreements_external_region',
        field: 'value',
        dynamicQueryKeys: {
        },
        rowTypesToQuery: [
          'vendors',
          'sites',
          'work-orders',
          'proposals',
          'events'
        ]
      },
      'proposal-number': {
        linkingTables: {
          'work-orders': {
            name: 'id',
            field: 'value'
          },
          proposals: '',
          vendors: 'proposals',
          sites: 'proposals',
          events: ''
        },
        name: 'estimate_number',
        field: 'value',
        dynamicQueryKeys: {
          workOrdersName: 'proposals_estimate_number',
          weatherEventsName: ''
        },
        rowTypesToQuery: [
          'vendors',
          'sites',
          'work-orders',
          'proposals',
          'events'
        ]
      },
      'proposal-custom-name': {
        linkingTables: {
          'work-orders': {
            name: 'id',
            field: 'workOrderId'
          },
          proposals: '',
          vendors: 'proposals',
          sites: 'proposals',
          events: ''
        },
        name: 'custom_proposal_name',
        field: 'value',
        dynamicQueryKeys: {
          workOrdersName: 'proposals_custom_proposal_name',
          weatherEventsName: ''
        },
        rowTypesToQuery: [
          'vendors',
          'sites',
          'work-orders',
          'proposals',
          'events'
        ]
      },
      'proposal-status': {
        linkingTables: {
          'work-orders': {
            name: 'id',
            field: 'workOrderId'
          },
          proposals: '',
          vendors: 'outbound_proposals_calculated_status',
          sites: 'proposals_calculated_status',
          events: ''
        },
        name: 'code',
        field: 'value',
        dynamicQueryKeys: {
          workOrdersName: 'proposals_calculated_status_code',
          weatherEventsName: ''
        },
        rowTypesToQuery: [
          'vendors',
          'sites',
          'work-orders',
          'proposals',
          'events'
        ]
      },
      'proposal-date-of-issue': {
        linkingTables: {
          'work-orders': {
            name: 'id',
            field: 'workOrderId'
          },
          proposals: '',
          vendors: 'proposals',
          sites: 'proposals',
          events: ''
        },
        name: 'date_of_issue',
        field: 'value',
        dynamicQueryKeys: {
          workOrdersName: 'proposals_date_of_issue',
          weatherEventsName: ''
        },
        rowTypesToQuery: [
          'vendors',
          'sites',
          'work-orders',
          'proposals',
          'events'
        ]
      },
      'proposal-expiration-date': {
        linkingTables: {
          'work-orders': {
            name: 'id',
            field: 'workOrderId'
          },
          proposals: '',
          vendors: 'proposals',
          sites: 'proposals',
          events: ''
        },
        name: 'proposal_date',
        field: 'value',
        dynamicQueryKeys: {
          workOrdersName: 'proposals_proposal_date',
          weatherEventsName: ''
        },
        rowTypesToQuery: [
          'vendors',
          'sites',
          'work-orders',
          'proposals',
          'events'
        ]
      },
      'proposal-client-name': {
        linkingTables: {
          'work-orders': {
            name: 'id',
            field: 'workOrderId'
          },
          proposals: '',
          vendors: 'proposals',
          sites: 'proposals',
          events: ''
        },
        name: 'company_name',
        field: 'value',
        dynamicQueryKeys: {
          workOrdersName: 'proposals_company_name',
          weatherEventsName: ''
        },
        rowTypesToQuery: [
          'vendors',
          'sites',
          'work-orders',
          'proposals',
          'events'
        ]
      },
      'proposal-site-name': {
        linkingTables: {
          'work-orders': {
            name: 'id',
            field: 'workOrderId'
          },
          proposals: '',
          vendors: 'proposals',
          sites: 'proposals',
          events: ''
        },
        name: 'location_name',
        field: 'value',
        dynamicQueryKeys: {
          workOrdersName: 'proposals_location_name',
          weatherEventsName: ''
        },
        rowTypesToQuery: [
          'vendors',
          'sites',
          'work-orders',
          'proposals',
          'events'
        ]
      },
      'weather-event': {
        linkingTables: {
          vendors: 'work_orders_trips_storm_details',
          sites: 'storm_details',
          proposals: 'work_orders_trips_storm_details',
          'work-orders': 'trips_storm_details',
          events: ''
        },
        name: 'id',
        field: 'id',
        dynamicQueryKeys: {
          workOrdersName: 'trips_storm_details_id',
          weatherEventsName: 'event_id'
        },
        rowTypesToQuery: [
          'vendors',
          'sites',
          'work-orders',
          'proposals',
          'events'
        ]
      },
      'weather-event-start-date': {
        linkingTables: {
          vendors: 'work_orders_trips_storm_details',
          sites: 'storm_details',
          proposals: 'work_orders_trips_storm_details',
          'work-orders': 'trips_storm_details',
          events: ''
        },
        name: 'start',
        dynamicQueryKeys: {
          workOrdersName: 'trips_storm_details',
          weatherEventsName: ''
        },
        rowTypesToQuery: [
          'vendors',
          'sites',
          'work-orders',
          'proposals',
          'events'
        ]
      },
      'weather-event-end-date': {
        linkingTables: {
          vendors: 'work_orders_trips_storm_details',
          sites: 'storm_details',
          proposals: 'work_orders_trips_storm_details',
          'work-orders': 'trips_storm_details',
          events: ''
        },
        name: 'end',
        dynamicQueryKeys: {
          workOrdersName: 'trips_storm_details',
          weatherEventsName: ''
        },
        rowTypesToQuery: [
          'vendors',
          'sites',
          'work-orders',
          'proposals',
          'events'
        ]
      },
      'client-name': {
        linkingTables: {
          vendors: '',
          sites: '',
          proposals: '',
          'work-orders': '',
          events: ''
        },
        name: 'work_order_company_name',
        field: 'value',
        dynamicQueryKeys: {
          workOrdersName: '',
          weatherEventsName: ''
        },
        rowTypesToQuery: [
          'vendors',
          'sites',
          'work-orders',
          'proposals',
          'events',
          'trips'
        ]
      },
      'service-channel-connection': {
        linkingTables: {
          vendors: 'work_orders',
          sites: 'work_orders',
          proposals: 'work_order',
          'work-orders': '',
          events: ''
        },
        name: 'trips_service_channel_connection_external_id',
        field: 'value',
        dynamicQueryKeys: {},
        rowTypesToQuery: [
          'vendors',
          'sites',
          'work-orders',
          'proposals',
          'events'
        ]
      },
      'corrigo-connection': {
        linkingTables: {
          vendors: 'work_orders',
          sites: 'work_orders',
          proposals: 'work_order',
          'work-orders': '',
          events: ''
        },
        name: 'corrigo_connection_external_id',
        field: 'value',
        dynamicQueryKeys: {},
        rowTypesToQuery: [
          'vendors',
          'sites',
          'work-orders',
          'proposals',
          'events'
        ]
      },
      'trip-creation-date': {
        linkingTables: {
          vendors: 'work_orders',
          sites: 'work_orders',
          proposals: 'work_order',
          'work-orders': '',
          events: ''
        },
        name: 'trips_start_date',
        field: 'value',
        dynamicQueryKeys: {
          workOrdersName: 'trips_trip_start_date',
          weatherEventsName: 'trips_trip_start_date'
        },
        rowTypesToQuery: [
          'vendors',
          'sites',
          'work-orders',
          'proposals',
          'events'
        ]
      },
      'work-order-service-check-in': {
        linkingTables: {
          vendors: '',
          sites: '',
          proposals: '',
          'work-orders': '',
          events: ''
        },
        name: 'work_orders_services_effective_check_in_time',
        field: 'value',
        dynamicQueryKeys: {},
        rowTypesToQuery: [
          'vendors',
          'sites',
          'work-orders',
          'proposals',
          'events'
        ]
      },
      'work-order-service-check-out': {
        linkingTables: {
          vendors: '',
          sites: '',
          proposals: '',
          'work-orders': '',
          events: ''
        },
        name: 'work_orders_services_effective_check_out_time',
        field: 'value',
        dynamicQueryKeys: {},
        rowTypesToQuery: [
          'vendors',
          'sites',
          'work-orders',
          'proposals',
          'events'
        ]
      },
      'trip-service-check-in': {
        linkingTables: {
          vendors: '',
          sites: '',
          proposals: '',
          'work-orders': '',
          events: ''
        },
        name: 'trips_services_effective_check_in_time',
        field: 'value',
        dynamicQueryKeys: {},
        rowTypesToQuery: [
          'vendors',
          'sites',
          'work-orders',
          'proposals',
          'events'
        ]
      },
      'trip-service-check-out': {
        linkingTables: {
          vendors: '',
          sites: '',
          proposals: '',
          'work-orders': '',
          events: ''
        },
        name: 'trips_services_effective_check_out_time',
        field: 'value',
        dynamicQueryKeys: {},
        rowTypesToQuery: [
          'vendors',
          'sites',
          'work-orders',
          'proposals',
          'events'
        ]
      }
    }
  }
}
