import { useInboundInvoices } from '@/use/invoices/inbound'
import { useOutboundInvoices } from '@/use/invoices/outbound'
import { useTrades } from '@/use/trades'
import { useTradeServices } from '@/use/trade_services'
import { useClients } from '@/use/clients'
import { useRegions } from '@/use/regions'
import { snakeCase, cloneDeep, capitalize } from 'lodash'
import { Trade, TradeService, Region } from '@/types/interfaces'
import { useAgreements } from '@/use/agreements'
import { AutocompletePayload, AutocompleteResponse } from '@/types/interfaces/api-v2/autocomplete'
import { ref } from 'vue'
import { FilterResources } from '@/types/enums/filter-types'
import { CompanyRoleTypes } from '@/types/enums'
import { useTextMapping } from '@/use/text-mappings'
import { useSession } from '@/use/session'
import { VENDOR } from '@/constants/permissions'

const { getTrades } = useTrades()
const { getTradeServices } = useTradeServices()
const { getClientBillingMethods } = useClients()
const { getExternalRegions } = useAgreements()
const { getRegions } = useRegions()
const { inboundInvoicesAutoComplete } = useInboundInvoices()
const { invoicesAutoComplete, statuses } = useOutboundInvoices()

const isOutbound = ref(false)

// const invoiceStatuses: Record <string, string> = {
//   Draft: 'draft',
//   Sent: 'sent',
//   Received: 'sent',
//   Paid: 'paid',
//   'Partially Paid': 'partially_paid',
//   Void: 'void',
//   Approved: 'approved',
//   Rejected: 'rejected',
//   'Approved To Send': 'approved_to_send'
// }

interface CalculatedStatus {
  code: number;
  text: string;
}

interface InvoiceAutocompletePayload extends AutocompletePayload {
  finderParams?: {
    includeGrouped?: boolean,
    objectScope?: string
  }
}

interface StatusTypes {
  standards: CalculatedStatus[]
  updatedWordings: CalculatedStatus[]
}
const invoiceStatuses: {
  [CompanyRoleTypes.CLIENT]: StatusTypes,
  [CompanyRoleTypes.VENDOR]: StatusTypes,
  both: StatusTypes
} = {
  client: { standards: [], updatedWordings: [] },
  vendor: { standards: [], updatedWordings: [] },
  both: { standards: [], updatedWordings: [] }
}

const isOutboundForStatus = (): boolean => {
  const { session } = useSession()

  return session.currentCompanyType === VENDOR
}

const getInvoiceStatuses = async (as: CompanyRoleTypes | 'both') => {
  const typeToConsider = isOutboundForStatus() ? 'updatedWordings' : 'standards'
  if (invoiceStatuses[as][typeToConsider].length) return invoiceStatuses[as][typeToConsider]

  const res = await statuses(as)
  if (res.data) {
    const statuses = cloneDeep(res.data)
    const { mapText } = useTextMapping()
    if (typeToConsider === 'updatedWordings') {
      statuses.forEach((status: CalculatedStatus) => {
        status.text = [8, 10].includes(status.code)
          ? capitalize(mapText(status.text))
          : status.text
      })
    }
    invoiceStatuses[as][typeToConsider] = statuses

    return invoiceStatuses[as][typeToConsider]
  } else {
    return []
  }
}

const invoiceAutoCompleteFetchFn = async (params: InvoiceAutocompletePayload) => {
  if (params.finderParams) {
    params.finderParams.includeGrouped = true
    params.finderParams.objectScope = 'both'
  } else {
    params.finderParams = { includeGrouped: true, objectScope: 'both' }
  }

  const { data, nextPage } = (isOutbound.value)
    ? await invoicesAutoComplete(params) as { data: AutocompleteResponse[], nextPage: number }
    : await inboundInvoicesAutoComplete(params) as { data: AutocompleteResponse[], nextPage: number }
  return {
    data: data.map((item: AutocompleteResponse, index: number) => {
      return {
        id: `${index}-${item.text}`,
        text: item.text,
        value: item.text
      }
    }),
    nextPage
  }
}

const INVOICE_NUMBER = (outbound: boolean, config = {}, params = { field: 'invoice_number' }, fetchFn: any = invoiceAutoCompleteFetchFn) => {
  isOutbound.value = outbound
  return {
    text: 'Number',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'invoice-number-filter',
      headerText: 'Invoice Number',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: ['invoice_number'],
      tokenSeparators: ['\t', '\n', ',']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.INVOICE,
    ...config
  }
}

const INVOICE_CUSTOM_NUMBER = (outbound: boolean, config = {}, params = { field: 'custom_invoice_number' }, fetchFn: any = invoiceAutoCompleteFetchFn) => {
  isOutbound.value = outbound
  return {
    text: 'Custom Number',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'invoice-custom-number-filter',
      headerText: 'Invoice Custom Number',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: ['custom_invoice_number'],
      tokenSeparators: ['\t', '\n', ',']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.INVOICE,
    ...config
  }
}

const INVOICE_STATUS = (as: CompanyRoleTypes | 'both', config = {}) => {
  return {
    text: 'Status',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'invoice-status-filter',
      headerText: 'Invoice Status',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn: async ({ terms }: { terms?: string }) => {
        const res = await getInvoiceStatuses(as)
        const data = cloneDeep(res).filter((calculatedStaus) => calculatedStaus.text.toLocaleLowerCase().includes(terms?.toLowerCase() || '')).map((calculatedStaus) => {
          return {
            id: calculatedStaus.code,
            text: calculatedStaus.text,
            value: calculatedStaus.code
          }
        })

        return {
          data,
          totalCount: data.length,
          totalPages: 1
        }
      },
      params: { q: { s: 'invoice_status asc' } },
      searchKeys: ['text']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.INVOICE,
    ...config
  }
}

const INVOICE_DATE_OF_ISSUE = (outbound: boolean, config = {}) => {
  return {
    text: 'Date of Issue',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'invoice-date-of-issue-filter',
      headerText: 'Invoice Date of Issue',
      size: 'md',
      contentType: 'date',
      rowType: 'text',
      showHeaders: true
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.INVOICE,
    ...config
  }
}

const INVOICE_DUE_DATE = (outbound: boolean, config = {}) => {
  return {
    text: 'Due Date',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'invoice-due-date-filter',
      headerText: 'Invoice Due Date',
      size: 'md',
      contentType: 'date',
      rowType: 'text',
      showHeaders: true
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.INVOICE,
    ...config
  }
}

const INVOICE_RECEIVED_DATE = (config = {}) => {
  return {
    text: 'Received Date',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'invoice-received-date-filter',
      headerText: 'Invoice Received Date',
      size: 'md',
      contentType: 'date',
      rowType: 'text',
      showHeaders: true
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.INVOICE,
    ...config
  }
}

const INVOICE_AGREEMENT_CONTRACT_NUMBER = (outbound: boolean, config = {}, params = { field: 'agreement_number' }, fetchFn: any = invoiceAutoCompleteFetchFn) => {
  isOutbound.value = outbound
  return {
    text: 'Schedule Service ID',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'invoice-schedule-service-filter',
      headerText: 'Schedule Service ID',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: ['agreement_number'],
      collectionPredicate: 'eq'
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.INVOICE,
    ...config
  }
}

const INVOICE_AGREEMENT_CUSTOM_NUMBER = (outbound: boolean, config = {}, params = { field: 'agreement_custom_no' }, fetchFn: any = invoiceAutoCompleteFetchFn) => {
  isOutbound.value = outbound
  return {
    text: 'Schedule Service Custom ID',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'invoice-schedule-service-custom-id-filter',
      headerText: 'Schedule Service Custom ID',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: ['agreement_custom_no']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.INVOICE,
    ...config
  }
}

const INVOICE_TRADE = (config = {}) => {
  return {
    text: 'Trade',
    canSelect: false,
    popoverProps: {
      textKey: 'name',
      rowType: 'text',
      showHeaders: true,
      height: 'unset',
      slotKey: 'invoice-trade-filter',
      headerText: 'Trade',
      fetchFn: async (params: { page: number, perPage?: number, q?: any }) => {
        const resp: { data: Trade[], totalCount: number, totalPages: number } | boolean = await getTrades(params)
        if (resp) {
          return {
            data: resp.data.map((item: Trade) => {
              delete item.icon
              return {
                ...item,
                tradeId: item.id,
                text: item.name
              }
            }),
            totalCount: resp.totalCount,
            totalPages: resp.totalPages
          }
        }
      },
      size: 'lg',
      contentType: 'string',
      searchKeys: ['name']
    },
    pinned: false,
    isPrimary: false,
    ...config
  }
}

const INVOICE_TRADE_SERVICE = (config = {}) => {
  return {
    text: 'Trade Service',
    canSelect: false,
    popoverProps: {
      textKey: 'name',
      rowType: 'text',
      showHeaders: true,
      height: 'unset',
      slotKey: 'invoice-trade-service-filter',
      headerText: 'Trade Service',
      fetchFn: async (params: { page: number, perPage?: number, q?: any }) => {
        const resp: { data: TradeService[], totalPages: number, totalCount: number } | boolean = await getTradeServices(params)
        if (resp) {
          return {
            data: resp.data.map((item: TradeService) => {
              return {
                ...item,
                text: item.name
              }
            }),
            totalCount: resp.totalCount,
            totalPages: resp.totalPages
          }
        }
      },
      size: 'lg',
      contentType: 'string',
      searchKeys: ['name']
    },
    pinned: false,
    isPrimary: false,
    ...config
  }
}

const INVOICE_BILLING_METHOD = (config = {}) => {
  return {
    text: 'Billing Method',
    canSelect: false,
    popoverProps: {
      textKey: 'text',
      rowType: 'text',
      showHeaders: true,
      height: 'unset',
      slotKey: 'invoice-billing-method-filter',
      headerText: 'Billing Method',
      fetchFn: async (params: { page: number, perPage?: number, q?: any }) => {
        const resp: { data: any, totalPages: number, totalCount: number } = await getClientBillingMethods(params)
        if (resp.data) {
          const data = resp.data.filter((e: string) => e !== 'Non Billable')
          return {
            data: data.map((item: any, index: number) => {
              return {
                id: index,
                text: item
              }
            }),
            totalCount: resp.totalCount,
            totalPages: resp.totalPages
          }
        }
      },
      size: 'lg',
      contentType: 'string',
      searchKeys: ['text']
    },
    pinned: false,
    isPrimary: false,
    ...config
  }
}

const INVOICE_REGION = (config = {}) => {
  return {
    text: 'Region',
    canSelect: false,
    popoverProps: {
      textKey: 'name',
      height: 'unset',
      slotKey: 'region-filter',
      headerText: 'Region',
      fetchFn: async (params: { page: number, perPage?: number, q?: any }) => {
        const resp: { data: Region[], totalPages: number, totalCount: number } = await getRegions({ ...params })
        if (resp) {
          return {
            data: resp.data?.map((item: Region, index: number) => {
              return {
                icon: '',
                text: item.name,
                id: item.id || index
              }
            }),
            totalCount: resp.totalCount,
            totalPages: resp.totalPages
          }
        }
      },
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      params: { q: { s: 'name desc' } },
      searchKeys: ['name'],
      pinned: false,
      isPrimary: false,
      ...config
    }
  }
}

const INVOICE_EXTERNAL_REGION = (outbound: boolean, config = {}, params = { field: 'inv_region' }, fetchFn: any = invoiceAutoCompleteFetchFn) => {
  isOutbound.value = outbound
  return {
    text: 'Invoice External Region',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'invoice-external-region-filter',
      headerText: 'Invoice External Region',
      size: 'lg',
      contentType: 'string',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: ['inv_region']
    },
    pinned: false,
    isPrimary: false,
    resource: FilterResources.INVOICE,
    ...config
  }
}

const INVOICE_CLIENT_PO_NUMBER = (outbound: boolean, config = {}, params = { field: 'po_number' }, fetchFn: any = invoiceAutoCompleteFetchFn) => {
  isOutbound.value = outbound
  return {
    text: 'Client PO Number',
    canSelect: true,
    popoverProps: {
      textKey: 'text',
      height: 'unset',
      slotKey: 'invoice-client-po-number-filter',
      headerText: 'Invoice Client PO Number',
      size: 'lg',
      contentType: 'number',
      rowType: 'text',
      showHeaders: true,
      fetchFn,
      params,
      searchKeys: ['po_number']
    },
    pinned: false,
    isPrimary: false,
    ...config
  }
}

export {
  INVOICE_NUMBER,
  INVOICE_CUSTOM_NUMBER,
  INVOICE_STATUS,
  INVOICE_DATE_OF_ISSUE,
  INVOICE_DUE_DATE,
  INVOICE_RECEIVED_DATE,
  INVOICE_AGREEMENT_CONTRACT_NUMBER,
  INVOICE_AGREEMENT_CUSTOM_NUMBER,
  INVOICE_TRADE,
  INVOICE_TRADE_SERVICE,
  INVOICE_BILLING_METHOD,
  INVOICE_REGION,
  INVOICE_EXTERNAL_REGION,
  INVOICE_CLIENT_PO_NUMBER
}
