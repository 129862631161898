import { useApp } from '@/use/app'
import { camelCase, capitalize, kebabCase, startCase, lowerCase } from 'lodash'
import { actionToastProps } from '@/constants/toast'
import { INVOICE_INCLUDE, PROPOSAL_INCLUDE, PO_INCLUDE } from '@/constants/include'
import { PRINT, PDF_DOWNLOAD, EXPORT, SEND } from '@/constants/icons'
import { INVOICE_RESOURCE_TYPE, PO_RESOURCE_TYPE, PROPOSAL_RESOURCE_TYPE } from '@/constants/resource-types'
import { canInjectIntoTable } from '@/utils/table'
import { MARKUP, MULTIPLIER } from '@/constants/line-items'
import { cleanResourceForPostPatchDB } from '@/utils/resource-details'
import { reactive, computed } from 'vue'
import { useToasts } from './toasts'
import { Client } from '@/types/interfaces'

const { setBottomHeight, setWorkflow, optionalDynamicComponentProps } = useApp()
const { addToast } = useToasts()

const errorMessage = (resourceType: string) => {
  return `Failed to process ${lowerCase(resourceType)}s. Please try again.`
}

const partialSuccessMessage = (resources: any[], actionableResources: any[]) => {
  let failedResources: any[] = resources
  for (const actionableResource of actionableResources) {
    failedResources = failedResources.filter((resource) => resource.id !== actionableResource.id)
  }
  if (failedResources.length <= 10) {
    const failedResourceIds = failedResources.map((failedResource: any) => `#${failedResource.invoiceNumber}`).join(' | ')
    return `Failed to update ${failedResources.length} of ${resources.length} records: ${failedResourceIds}. Please refresh and try again.`
  } else {
    return `Failed to update ${failedResources.length} of ${resources.length} records. Please refresh and try again.`
  }
}

const successMessage = (resources: any[], action: string, resourceType:string) => {
  if (action === 'delete') {
    let message = ''
    if (resourceType === PROPOSAL_RESOURCE_TYPE) {
      message = message = resources.length === 1 ? `${capitalize(resourceType)} #${resources[0]?.[fetchResourceTypeConfigObj(resourceType)?.numberIdKey]} was` : `selected ${resourceType}s were`
      return `${message} were successfully deleted`
    } else if (resourceType === PO_RESOURCE_TYPE) {
      message = resources.length === 1 ? `${startCase(resourceType)} #${resources[0]?.poNumber} was` : `selected ${startCase(resourceType)}s were`
      return `${capitalize(message)} successfully deleted`
    } else {
      message = resources.length === 1 ? `${resourceType} #${resources[0]?.[fetchResourceTypeConfigObj(resourceType)?.numberIdKey]} was` : `selected ${resourceType}s were`
      return `${capitalize(message)} successfully deleted`
    }
  } else {
    return `${resources.length} record(s) successfully processed`
  }
}
const configureDetailsPanel = (resources:any, toggleFullScreenView: any, setSelectedResources: any) => {
  toggleFullScreenView(true)
  if (resources.length > 1) {
    setSelectedResources(resources)
  }
}

const sendResources = async (resources: any[], type: string, fetchFn = null, params = {}) => {
  // type should be in camelCase
  optionalDynamicComponentProps.value.props = { ...(fetchFn ? { type, clients: [], resources: [] } : getSendProps(resources, type)), fetchFn, params }
  setWorkflow('uc/resource/send')
}
const genActionableResources = (actionText: string, resources: any[], statusValidatorObj:any) => {
  const actions = [...statusValidatorObj.directionalActionData]
  let allowedStatuses = actions.find((action: any) => action.name === (startCase(actionText))).validStatuses
  if ((typeof allowedStatuses === 'boolean') && allowedStatuses) {
    allowedStatuses = statusValidatorObj.statuses
  }
  return resources.filter((resource: any) => {
    return allowedStatuses.includes(resource.status)
  })
}

const receivePaymentForResources = async (params: {fetchFn: any, selectAllParams: any, pdfViewerRef: any}) => {
  params.pdfViewerRef.setLoading(true)
  const invoices = await params.fetchFn(params.selectAllParams)
  optionalDynamicComponentProps.value.props = {
    invoices: invoices.data
  }
  params.pdfViewerRef.setLoading(false)
  setWorkflow('invoice/receive-payments')
}

const makePaymentForResources = async (params: {fetchFn: any, selectAllParams: any, pdfViewerRef: any}) => {
  params.pdfViewerRef.setLoading(true)
  const invoices = await params.fetchFn(params.selectAllParams)
  optionalDynamicComponentProps.value.props = {
    invoices: invoices.data,
    receive: false
  }
  params.pdfViewerRef.setLoading(false)
  setWorkflow('invoice/receive-payments')
}

const configureForReviewDetailsPanel = async (fetchFn: any, selectAllParams: any, toggleFullScreenView: any, setSelectedResources: any) => {
  const invoices = await fetchFn(selectAllParams)
  configureDetailsPanel(invoices.data, toggleFullScreenView, setSelectedResources)
}

const globalResourceActionToResultingStatusMap = (type: string) => {
  const resourceActionMap:any = {
    invoice: {
      'assign-department': { status: '', actionText: 'Assign Department', fieldKey: 'department_id' },
      'mark-sent': { status: 'sent', actionText: 'Mark Sent', fieldKey: 'status' },
      'approve-to-send': { status: 'approved_to_send', actionText: 'Approve To Send', fieldKey: 'status' },
      approve: { status: 'approved', actionText: 'Approve', fieldKey: 'status' },
      reject: { status: 'rejected', actionText: 'Reject' },
      void: { status: 'void', actionText: 'Void', fieldKey: 'status' },
      delete: { status: '', actionText: 'Delete', fieldKey: 'delete' },
      confirm: { status: 'sent', actionText: 'Confirm', fieldKey: 'status' },
      deny: { status: 'rejected', actionText: 'Deny' }
    },

    proposal: {
      approve: { status: 'accepted', actionText: 'Approve', fieldKey: 'approve' },
      close: { status: 'closed', actionText: 'Close', fieldKey: 'close' },
      'ready-for-review': { status: 'ready_for_review', actionText: 'Ready For Review', fieldKey: 'ready_for_review' },
      draft: { status: 'draft', actionText: 'Draft', fieldKey: 'draft' },
      evaluate: { status: 'evaluating', actionText: 'Evaluate', fieldKey: 'evaluate' },
      decline: { status: 'rejected', actionText: 'Reject', fieldKey: 'decline' },
      delete: { status: '', actionText: 'Delete', fieldKey: 'delete' }
    },

    'purchase-order': {
      approve: { status: 'approved', actionText: 'Approve', fieldKey: 'status' },
      decline: { status: 'rejected', actionText: 'Reject', fieldKey: 'decline' },
      close: { status: 'closed', actionText: 'Close', fieldKey: 'close' },
      delete: { status: '', actionText: 'Delete', fieldKey: 'delete' },
      'assign-department': { status: '', actionText: 'Assign Department', fieldKey: 'department_id' }
    }
  }
  return resourceActionMap[type]
}

const fetchResourceTypeConfigObj = (type: string) => {
  const resourceMap:any = {
    invoice:
  {
    editableStatuses: ['draft', 'unsaved', 'approved_to_send', 'rejected', 'approved'],
    numberIdKey: 'invoiceNumber'
  },
    proposal:
  {
    editableStatuses: ['draft', 'ready_for_review', 'unsaved', 'evaluating', 'sent'],
    numberIdKey: 'estimateNumber'
  },
    'purchase-order':
  {
    editableStatuses: ['draft', 'unsaved', 'rejected'],
    numberIdKey: 'poNumber'
  }
  }
  return resourceMap[type]
}

const resourceModalConfirmationHandler = async (
  action: string,
  resources: any[],
  additionalParams:any,
  resourceType:string,
  updateDBFn:any = null,
  statusValidatorObj:any,
  outbound:boolean
) => {
  const { detailsRef, removeRows, updateRow, rowProps } = additionalParams

  const transformedResources = resources.map(resource => cleanResourceForPostPatchDB(
    resource,
    {
      original: [],
      updated: resourceType === INVOICE_RESOURCE_TYPE
        ? resource.invoiceItems
        : resourceType === PROPOSAL_RESOURCE_TYPE ? resource.proposalItems : resource.purchaseOrderItems
    },
    resourceType,
    outbound,
    false,
    {
      markupType: resourceType === INVOICE_RESOURCE_TYPE
        ? MARKUP
        : MULTIPLIER,
      lineItemDataMap: {
        updateContentKey: resourceType === INVOICE_RESOURCE_TYPE
          ? 'invoice_items_attributes'
          : resourceType === PROPOSAL_RESOURCE_TYPE ? 'proposal_items_attributes' : 'purchase_order_items_attributes'
      }
    },
    resource.companyId,
    resource.vendorId,
    false,
    [])[0]
  )

  const resourceTypeMap = globalResourceActionToResultingStatusMap?.(resourceType)

  if (action) {
    let actionableResources = (genActionableResources(
      Object.keys(resourceTypeMap).includes(action)
        ? resourceTypeMap[kebabCase(action)].actionText
        : startCase(action),
      transformedResources,
      statusValidatorObj
    ))

    if (transformedResources.length > 1 || additionalParams.bulkSelectionData.isBulkExclusive) {
      actionableResources = transformedResources
    }
    const res: any = await deleteOrUpdateResources(
      action === 'assign-department' ? 'department_id' : action,
      actionableResources,
      additionalParams,
      resourceType,
      updateDBFn,
      outbound || rowProps?.outbound)
    // did we just complete a bulk action? if so, check the arr of res.canRemoveRows, crosschecking each resource, its index to determine if if we should remove the rows.
    // if we can remove, send to removeRows, else update row
    let rowsToRemove:any = []
    let rowsToUpdate:any = []
    if (Array.isArray(res.canRemoveRows)) {
      rowsToRemove = res.resources.filter((_resource:any, index:number) => res.canRemoveRows[index])
      rowsToUpdate = res.resources.filter((_resource:any, index:number) => !res.canRemoveRows[index])
    } else {
      // we have taken a single action on a row and we should remove/update the row depending on if res.canRemoveRows
      rowsToRemove = res.canRemoveRows ? res.resources : []
      rowsToUpdate = res.canRemoveRows ? [] : res.resources
    }

    if (additionalParams.isFromTable) {
      if (rowsToRemove.length) {
        removeRows(rowsToRemove)
      }

      rowsToUpdate.forEach((resource: any) => {
        updateRow(resource)
      })
    }

    if (res.resources.length === 1 && detailsRef?.localSelectedResource?.id) {
      detailsRef.localSelectedResource = {
        ...detailsRef?.localSelectedResource,
        status: res.resources[0].status,
        statusTxt: res.resources[0].statusTxt,
        department: res.resources[0].department || null
      }
      if (resourceType === PROPOSAL_RESOURCE_TYPE) delete detailsRef.localSelectedResource.department
    }

    const toastDetails: any = {}
    const milliseconds = !res.success ? 5000 : 10000
    toastDetails.timeout = milliseconds
    if (res.success && resources.length === actionableResources.length) {
      toastDetails.message = successMessage(res.resources, res.fieldKey === 'department_id' ? 'department' : res.fieldKey || '', resourceType)
      toastDetails.prependIcon = actionToastProps.success.icon
      toastDetails.color = actionToastProps.success.state
    } else if (actionableResources.length && resources.length > actionableResources.length) {
      toastDetails.message = partialSuccessMessage(resources, actionableResources)
      toastDetails.prependIcon = actionToastProps.error.icon
      toastDetails.color = actionToastProps.error.state
    } else {
      toastDetails.message = errorMessage(resourceType)
      toastDetails.prependIcon = actionToastProps.error.icon
      toastDetails.color = actionToastProps.error.state
    }
    addToast({ ...toastDetails })
    additionalParams.hidePanel()
  }
}

const resourceTypeIncludeMap:any = {
  invoice: INVOICE_INCLUDE,
  proposal: PROPOSAL_INCLUDE,
  purchaseOrder: PO_INCLUDE
}
const deleteOrUpdateResources = async (
  action:string,
  resources: any[],
  additionalParams: any,
  resourceType:string,
  updateDBFn:any,
  outbound = true as boolean

) => {
  const RESOURCE_INCLUDE = resourceTypeIncludeMap[resourceType]
  const resourceTypeMap:any = globalResourceActionToResultingStatusMap(resourceType)
  if (resources.length < 1) {
    console.error('Unable to take action. No resources have been selected.')
  }

  let res:any
  let params:any

  // fieldKey - INV: ENUM ['status', 'delete', 'department_id']
  // fieldKey - PROPOSAL - ENUM ['evaluate', 'close, 'approve', 'delete', 'decline'] - status change updates are handled in the api automatically, are not updated similar to inv
  const proposalModalDependentUpdateActions = ['evaluate', 'close', 'approve', 'decline', 'reject']
  const invoiceSpecialHandlingActions = ['department_id', 'delete']
  const fieldKey = (invoiceSpecialHandlingActions.includes(action)) || (proposalModalDependentUpdateActions.includes(action) && resourceType === PROPOSAL_RESOURCE_TYPE) ? action : 'status'
  const bulkStatusUpdate = (fieldKey !== 'delete' && resourceType === INVOICE_RESOURCE_TYPE && resources.length > 1)
  const isSingleActionOrNotAnExportQuery = resources.length === 1
  const isFromTable = !!additionalParams?.isFromTable
  // if action is approve to send and coming as bulk opertion, treat it as a bulk operation
  const isBulkApproveToSend = isFromTable && ((additionalParams.trackedResourceIds.length >= 1 || additionalParams.bulkSelectionData.isSelectAll) && action === 'approve-to-send')

  let fieldValue:any
  // proposal reject/approve apis take /decline for reject and /accept for approve -> have to swtch these for actual resulting statuses come optimistic update time
  if (proposalModalDependentUpdateActions.concat(invoiceSpecialHandlingActions[0]).includes(fieldKey)) {
    if (action === 'department_id' && resourceType !== PROPOSAL_RESOURCE_TYPE) {
      fieldValue = additionalParams?.additionalDataFromActionModal?.data
    } else {
      fieldValue = additionalParams?.additionalDataFromActionModal?.note
    }
  } else {
    fieldValue = resourceTypeMap[kebabCase(action)].status
  }
  let resourceForPatchUpdate:any = {}

  // come back to this and add in props and pos
  if (isSingleActionOrNotAnExportQuery && !isBulkApproveToSend) {
    // update resource
    resourceForPatchUpdate = {
      ...resources[0],
      [fieldKey]: fieldValue
    }
    if (fieldKey === 'department_id') {
      // unnecessary to pass entire department obj -- only need department_id. department is returned in the response as per RESOURCE_INCLUDE
      delete resourceForPatchUpdate.department
    }

    const idKey = `${camelCase(resourceType)}Id`
    if (resourceType === INVOICE_RESOURCE_TYPE) {
      params = fieldKey !== 'delete'
        ? {
            [idKey]: resourceForPatchUpdate.id,
            updateContent: resourceForPatchUpdate,
            include: RESOURCE_INCLUDE,
            objectScope: 'both' // required to support multi-resource and single resource invoices
          }
        : resourceForPatchUpdate.id
    } else if (resourceType === PROPOSAL_RESOURCE_TYPE) {
      // add in reason with note
      params = fieldKey !== 'delete'
        ? {
            action,
            [idKey]: resources[0].id,
            reason: fieldValue,
            include: RESOURCE_INCLUDE
          }
        : resources[0].id
      if (fieldKey === 'approve') {
        params.annexedPoNumber = additionalParams?.additionalDataFromActionModal['annexed-po-number']
      }
    } else if (resourceType === PO_RESOURCE_TYPE) {
      params = fieldKey !== 'delete'
        ? {
            [idKey]: resourceForPatchUpdate.id,
            updateContent: resourceForPatchUpdate,
            include: RESOURCE_INCLUDE
          }
        : resourceForPatchUpdate.id
    }
    res = await updateDBFn(params)
    if (!res || res.error) {
      return {
        resources: [],
        success: false,
        fieldKey,
        action,
        canRemoveRows: false
      }
    }
    let newResources:any = []
    const resource:any = res?.[`${camelCase(kebabCase(resourceType))}`]
    let inject = true
    if (resource && resource.id && isFromTable) {
      const data = await canInjectIntoTable([resource.id], additionalParams.canRemoveRowProps.fetchFn, additionalParams.exportQuery, additionalParams.canRemoveRowProps.params)
      inject = !!data.length
    } else if (fieldKey === 'delete') {
      inject = false
    }
    if (fieldKey === 'delete') {
      newResources = [resourceForPatchUpdate]
    } else {
      newResources = [resource]
    }

    return {
      resources: newResources,
      success: true,
      fieldKey: fieldKey === 'decline' ? 'reject' : fieldKey,
      action,
      canRemoveRows: !inject

    }
  } else {
    let query = {}
    if ((bulkStatusUpdate || isBulkApproveToSend) || additionalParams.bypassBulkAction) {
      query = additionalParams?.exportQuery()
      params = {
        field: fieldKey,
        value: fieldValue,
        query: JSON.stringify(query)
      }
    } else {
      query = additionalParams?.exportQuery()
      params = {
        query: JSON.stringify(query)
      }
    }

    res = await updateDBFn(params)
  }
  if (!res?.success?.length || !res.success) {
    return {
      resources: [],
      success: false,
      fieldKey,
      action,
      canRemoveRows: false
    }
  }

  const successResources = bulkStatusUpdate
    ? res.success.map((resourceId: number) => {
      return resources.findIndex((resource: any) => resource.id === resourceId)
    })
    : resources

  let retResources = []
  if (fieldKey !== 'delete') {
    retResources = successResources.map((resource: number | object) => {
      // if action is performed on a single record, resource will be an object
      const res = (typeof resource === 'number') ? resources[resource] : resource
      const resourceMap = resourceTypeMap[kebabCase(action)]
      const updatedStatus: {
        status?: string,
        statusTxt?: string
      } = {}

      if (resourceMap) {
        updatedStatus.status = resourceMap.status
        updatedStatus.statusTxt = startCase(resourceMap.status)
      }

      return {
        ...res,
        ...updatedStatus
      }
    })
  } else {
    retResources = successResources
  }

  const canRemoveResourcesMapping: boolean[] = []
  for (const resource of retResources) {
    let shouldRemove = false
    if (resource && resource.id) {
      // will return a number of sucessfull operations
      const data = await canInjectIntoTable(
        [resource.id],
        additionalParams.canRemoveRowProps.fetchFn,
        additionalParams.exportQuery,
        additionalParams.canRemoveRowProps.params
      )
      shouldRemove = !!data.length

      // do not remove the row when status and department_id fields are getting patched.
      // if query filters are applied and it does not match the criteria row will be removed
      shouldRemove = !(shouldRemove && ['status', 'department_id'].indexOf(fieldKey) !== -1)
    } else if (fieldKey === 'delete') {
      shouldRemove = true
    }
    canRemoveResourcesMapping.push(shouldRemove)
  }

  return {
    resources: retResources,
    success: !res.errors,
    fieldKey,
    action,
    canRemoveRows: canRemoveResourcesMapping
  }
}

const getActionMenuData = (resourceStatus: string, { directionalActionData, universalActions }:any, outbound = true, role = '') => {
  const returnActions: any[] = []
  const availableActions:any = [...directionalActionData]
  availableActions.push(...universalActions(role).map((action: any) => {
    const key = outbound ? 'outbound' : 'inbound'
    const validStatusesCopy = Array.isArray(action.validStatuses[key]) ? [...action.validStatuses[key]] : action.validStatuses[key]
    const obj:any = {
      name: action.name,
      validStatuses: validStatusesCopy,
      primaryAction: outbound ? [...action.primaryAction.outbound] : [...action.primaryAction.inbound],
      textColor: action.textColor,
      detailsPanel: action.detailsPanel

    }
    return {
      ...obj
    }
  }))
  availableActions.forEach((action: any) => {
    const a:any = {
      text: action.name,
      icon: '',
      textColor: action.textColor,
      detailsPanel: action.detailsPanel

    }
    if (action.savedStatus) {
      a.savedStatus = action.savedStatus
    }

    if (
      (!Array.isArray(action.primaryAction) && action.primaryAction) ||
      action.primaryAction.includes(resourceStatus)
    ) {
      returnActions.unshift(a)
    } else if (
      (!Array.isArray(action.validStatuses) && action.validStatuses) ||
      action.validStatuses.includes(resourceStatus)
    ) {
      returnActions.push(a)
    }
  })
  return returnActions
}

const showResourceAccessErrorToast = (toast:any) => {
  const toastDetails: any = {}
  toastDetails.color = actionToastProps.error.state
  toastDetails.message = 'Resource Unavailable'
  toastDetails.prependIcon = actionToastProps.error.icon
  toast.show({ ...toastDetails })
}

const sharedBasicActions = (listeners:any, outbound = true) => {
  return [
    {
      value: 'export',
      bulkConfig: {
        type: 'icon',
        icon: EXPORT,
        text: 'Export Data'
      },
      actionFn: (resources: any[], additionalParams: any) => {
        listeners.export(
          resources,
          additionalParams,
          outbound || additionalParams?.rowProps?.outbound || !!additionalParams.detailsPanelComponentProps?.outbound
        )
      },
      noSuccessEmit: true
    },
    {
      value: 'print',
      bulkConfig: {
        type: 'icon',
        icon: PRINT,
        text: 'Print'
      },
      actionFn: (resources: any[], additionalParams: any) => {
        listeners.print(
          resources,
          additionalParams,
          additionalParams?.rowProps?.outbound || !!additionalParams.detailsPanelComponentProps?.outbound
        )
      },
      noSuccessEmit: true
    },
    {
      value: 'share',
      actionFn: (resources: any[], additionalParams: any) => {
        listeners.share(
          resources,
          additionalParams,
          outbound || additionalParams?.rowProps?.outbound || !!additionalParams.detailsPanelComponentProps?.outbound
        )
      },
      noSuccessEmit: true
    }
  ]
}

const getUnsavedActions = (resourceType:string, resourceTypeActionFn:any = null) => {
  return [{
    value: 'save-and-send',
    validResourceTypes: [INVOICE_RESOURCE_TYPE, PROPOSAL_RESOURCE_TYPE, PO_RESOURCE_TYPE],
    actionParams: {
      confirmationText: 'SEND',
      status: 'sent',
      hasModal: true
    }
  },
  {
    value: 'save-and-approve-to-send',
    validResourceTypes: [INVOICE_RESOURCE_TYPE],
    actionParams: {
      confirmationText: '',
      status: 'approved_to_send',
      hasModal: false
    }
  },
  {
    value: 'save-as-draft',
    validResourceTypes: [INVOICE_RESOURCE_TYPE, PROPOSAL_RESOURCE_TYPE, PO_RESOURCE_TYPE],
    actionParams: {
      confirmationText: '',
      status: 'draft',
      hasModal: false
    }
  },
  {
    value: 'save-and-mark-sent',
    validResourceTypes: [INVOICE_RESOURCE_TYPE, PROPOSAL_RESOURCE_TYPE, PO_RESOURCE_TYPE],
    actionParams: {
      confirmationText: '',
      status: 'sent',
      hasModal: false
    }
  },
  {
    value: 'save-and-receive',
    validResourceTypes: [INVOICE_RESOURCE_TYPE, PROPOSAL_RESOURCE_TYPE, PO_RESOURCE_TYPE],
    actionParams: {
      confirmationText: '',
      status: 'sent',
      hasModal: false
    }
  },
  {
    value: 'save-and-approve',
    validResourceTypes: [INVOICE_RESOURCE_TYPE, PROPOSAL_RESOURCE_TYPE, PO_RESOURCE_TYPE],
    actionParams: {
      confirmationText: '',
      status: 'draft',
      hasModal: false
    },
    actionFn: resourceTypeActionFn
  },
  {
    value: 'save-to-review',
    validResourceTypes: [INVOICE_RESOURCE_TYPE],
    actionParams: {
      confirmationText: '',
      status: 'sent',
      hasModal: false
    }
  }
  ].filter((action:any) => action.validResourceTypes.includes(resourceType))
}

const basicOutboundActions = (listeners:any) => {
  return [
    {
      value: 'send',
      bulkConfig: {
        type: 'icon',
        icon: SEND,
        text: 'Send'
      },
      actionFn: (resources: any[], additionalParams: any) => {
        listeners.send(
          resources,
          additionalParams
        )
      }
    },
    {
      value: 'edit',
      updateSelectedResource: true,
      bulkConfig: {
      },
      actionFn: (resources: any[], additionalParams: any) => {
        listeners.edit(
          resources,
          additionalParams,
          true
        )
      },
      noSuccessEmit: true
    }
  ]
}

const getClientsFromResources = (resources: any[], type:string) => {
  const uniqueClients: Client[] = []
  return [
    ...resources.map((resource: any) => {
      // if the client is not found in our uniqueClients array we can append it to the return array
      if (!uniqueClients.find((client: any) => {
        return client.id === (type === PO_RESOURCE_TYPE ? resource.vendor.id : resource.company.id)
      })) {
        // after a client is found, add it to the unique resource array
        if (type === PO_RESOURCE_TYPE) {
          uniqueClients.push(resource.vendor)
          return {
            props: {
              client: {
                ...resource.vendor
              }
            },
            id: resource.vendor.id
          }
        } else {
          uniqueClients.push(resource.company)
          return {
            props: {
              client: {
                ...resource.company
              }
            },
            id: resource.company.id
          }
        }
      } else {
        // return null if we shouldn't add this client to the list so we can then filter once mapping is complete
        return null
      }
    }).filter((client: any) => client !== null)
  ]
}

// Universal sendEmail functions

const getSendProps = (resources: any[], type:string) => {
  return {
    // pass resource list
    resources,
    // provide the resource type prop for dynamic use
    type,
    // gather the unique clients associated to each invoice.
    clients: getClientsFromResources(resources, type)
  }
}

const reasonsForDisable = reactive({
  nteExceeded: { reason: 'NTE has been exceeded. Please resolve to save invoices.', value: false },
  incompleteOrUnchanged: { reason: 'Content unchanged or missing required fields', value: true },
  rateLinkRequired: { reason: 'Invalid rates present. All rates must be linked to items to save this invoice', value: false },
  customNumberRequired: { reason: 'Custom number is required.', value: false },
  descriptionRequired: { reason: 'Invoice Description is required', value: false }
}) as Record<string, {reason: string, value: boolean}>

export const useTransactionResources = () => {
  const resetReasonsForDisable = () => {
    const disableReasonValues = Object.values(reasonsForDisable)
    disableReasonValues.forEach((disable: {reason: string, value: boolean}) => {
      disable.value = false
    })
    // by default content is unchanged so this must be true, the rest is false
    reasonsForDisable.incompleteOrUnchanged.value = true
  }

  const disableHeaderActions = computed(() => {
    const disableReasonKeys = Object.keys(reasonsForDisable)
    let reason = ''
    const disabled = disableReasonKeys.reduce((isDisabled: boolean, key: string) => {
      if (!isDisabled && reasonsForDisable[key]) {
        reason = reasonsForDisable[key].reason
      }
      isDisabled = isDisabled || reasonsForDisable[key].value
      return isDisabled
    }, false)
    return { disabled, reason }
  })

  return {
    getSendProps,
    basicOutboundActions,
    getUnsavedActions,
    errorMessage,
    successMessage,
    partialSuccessMessage,
    sendResources,
    configureDetailsPanel,
    genActionableResources,
    globalResourceActionToResultingStatusMap,
    fetchResourceTypeConfigObj,
    resourceModalConfirmationHandler,
    deleteOrUpdateResources,
    getActionMenuData,
    sharedBasicActions,
    showResourceAccessErrorToast,
    reasonsForDisable,
    disableHeaderActions,
    resetReasonsForDisable,
    getClientsFromResources,
    receivePaymentForResources,
    makePaymentForResources,
    configureForReviewDetailsPanel
  }
}
