import { defineComponent, computed, watch } from 'vue';
import _, { cloneDeep, lowerCase } from 'lodash';
import { INVOICE_RESOURCE_TYPE } from '@/constants/resource-types';
export default defineComponent({
  props: {
    showSelectAll: {
      type: Boolean,
      default: true
    },
    isBulkSelected: {
      type: Boolean,
      default: false
    },
    actions: {
      type: Array,
      default: () => []
    },
    buttonMenuProps: {
      type: Object,
      default: null
    },
    noneSelected: {
      type: Boolean,
      default: false
    },
    isAnySelected: {
      type: Boolean,
      default: false
    }
  },
  setup: (props, {
    emit
  }) => {
    watch([() => props.isBulkSelected], () => {
      emit('update:bulk-selection-data', {
        isSelectAll: props.isBulkSelected
      });
    });
    const componentMenuProps = computed(() => {
      if (props.buttonMenuProps) {
        var _props$buttonMenuProp;
        if (((_props$buttonMenuProp = props.buttonMenuProps.menuData) === null || _props$buttonMenuProp === void 0 ? void 0 : _props$buttonMenuProp.rowType) === INVOICE_RESOURCE_TYPE) {
          const tempData = cloneDeep(props.buttonMenuProps);
          tempData.menuData.rows.forEach(row => {
            if (['approve', 'reject'].includes(lowerCase(row.text))) {
              row.eventName = lowerCase(row.text);
            }
          });
          return tempData;
        } else {
          return props.buttonMenuProps;
        }
      } else {
        return undefined;
      }
    });
    const calculatedActions = computed(() => {
      return props.actions.map(action => {
        return {
          ...action
        };
      });
    });
    const hasButtonMenu = computed(() => {
      return !!componentMenuProps.value && Object.keys(componentMenuProps.value).length;
    });
    const menuListenerObj = computed(() => {
      var _componentMenuProps$v, _componentMenuProps$v2, _componentMenuProps$v3, _componentMenuProps$v4;
      const temp = {};
      if (Array.isArray((_componentMenuProps$v = componentMenuProps.value) === null || _componentMenuProps$v === void 0 ? void 0 : (_componentMenuProps$v2 = _componentMenuProps$v.menuData) === null || _componentMenuProps$v2 === void 0 ? void 0 : _componentMenuProps$v2.rows)) {
        componentMenuProps.value.menuData.rows.forEach(row => {
          const tempFn = () => {
            emit(`${_.kebabCase(row.eventName || row.text)}`);
          };
          temp[`${_.kebabCase(row.eventName || row.text)}`] = tempFn;
        });
      }
      if (typeof ((_componentMenuProps$v3 = componentMenuProps.value) === null || _componentMenuProps$v3 === void 0 ? void 0 : (_componentMenuProps$v4 = _componentMenuProps$v3.buttonProps) === null || _componentMenuProps$v4 === void 0 ? void 0 : _componentMenuProps$v4.text) === 'string') {
        temp[`${_.kebabCase(componentMenuProps.value.buttonProps.eventName || componentMenuProps.value.buttonProps.text)}`] = () => emit(`${_.kebabCase(componentMenuProps.value.buttonProps.eventName || componentMenuProps.value.buttonProps.text)}`);
      }
      return temp;
    });
    const isIndeterminate = computed(() => props.isBulkSelected ? false : props.isAnySelected);
    return {
      calculatedActions,
      hasButtonMenu,
      menuListenerObj,
      isIndeterminate,
      componentMenuProps
    };
  }
});